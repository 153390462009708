
.main-content {
    position: fixed;
    top: 60px;
    bottom: 0;
    width: 100%;
    background: #F5F7F6;

    .main-wrap {
        display: flex;
        padding: 20px 0;
        height: 100%;
        margin: 0 auto;
        overflow: auto;
        justify-content: center;
        box-sizing: border-box;

        .btn-content {
            display: flex;
            flex-direction: column;
            align-self: flex-end;
            padding-left: 20px;

            .btn {
                width: 64px;
                height: 64px;
                background: #fff;
                border: 1px solid #D2D2D2;
                color: #A0A0A0;
                border-radius: 4px;
                margin-left: 10px;

                span {
                    display: flex;
                    flex-direction: column;

                    i {
                        height: 16px;
                        line-height: 0;
                        font-size: 20px;
                        color: #A0A0A0;
                    }
                }
            }

            .btn-blue {
                width: 64px;
                height: 64px;
                border: 1px solid #2461EF;
                color: #2461EF;
                border-radius: 4px;
                margin: 30px 10px;

                span {
                    display: flex;
                    flex-direction: column;

                    i {
                        height: 16px;
                        line-height: 0;
                        font-size: 20px;
                    }
                }
            }


            .btn-g {
                width: 64px;
                height: 64px;
                border: 1px solid #2DC079;
                color: #2DC079;
                border-radius: 4px;
                margin-top: 13px;

                span {
                    margin-left: -8px;
                }
            }
        }
    }
}
