
.info-wrap {

    .report-title {
        font-size: 18px;
        position: relative;

        &:before {
            content: '';
            width: 5px;
            height: 20px;
            background: #2DC079;
            border-radius: 3px;
            position: absolute;
            left: -13px;
            top: 3px;
        }
    }

    .head-box {
        display: flex;

        .head-left {
            background: #fff;
            border-radius: 8px;

            .head-wrap {
                padding: 35px 125px 35px 30px;
            }

            .left-main {
                display: flex;
                margin-top: 31px;

                .left-main-content {
                    margin-left: 31px;

                    .stu-name {
                        font-size: 30px;
                        font-weight: 500;
                    }

                    .left-item {
                        margin-top: 23px;
                        display: flex;
                        align-items: center;

                        .item-name {
                            width: 90px;
                            font-size: 18px;
                            color: #666666;

                            &.gender {
                                width: 72px;
                                display: flex;
                                justify-content: space-between;
                            }
                        }

                        .item-content {
                            font-size: 18px;
                            color: #333333;

                            .gender-box {
                                i {
                                    font-size: 18px;
                                }

                                &.female {
                                    i {
                                        color: #FF3EC9;
                                    }
                                }

                                &.male {
                                    i {
                                        color: #75B9EB;
                                    }
                                }
                            }

                            &.score {
                                font-size: 30px;
                                color: #F23B2F;
                            }
                        }
                    }
                }
            }

            img {
                width: 200px;
                height: 280px;
            }
        }

        .head-right {
            background: #fff;
            margin-left: 10px;
            border-radius: 8px;

            .head-wrap {
                padding: 35px 65px 35px 30px;
            }

            .item-content {
                margin-top: 24px;
                width: 300px;
                height: 250px;

                #scoreCondition {
                    background: url('../assets/images/teacher/star-background.png')
                }
            }
        }
    }

    .info-item {
        margin-top: 20px;
        background: #FFFFFF;
        border-radius: 8px;

        .item-wrap {
            padding: 33px 30px 0;
        }

        .item-content {
            padding: 33px 100px 20px;
            display: flex;
            justify-content: space-between;

            .item-progress {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .progress-content {
                    text-align: center;
                }

                ::v-deep.el-progress {

                    .el-progress-circle {
                        width: 170px !important;
                        height: 170px !important;
                    }

                    .el-progress__text {
                        width: 92px;
                        height: 92px;
                        background: #F9F9F9;
                        border-radius: 50%;
                        left: 24%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 24px;
                        font-weight: bold;
                        color: #313131;
                    }
                }

                .progress-title {
                    text-align: center;
                    margin-top: 20px;
                    font-size: 20px;
                    font-weight: 500;
                }
            }
        }
    }

    .middle-box {
        margin-top: 20px;
        background: #FFFFFF;
        border-radius: 8px;

        .middle-wrap {
            padding: 33px 30px 0;
        }

        .item-content {
            margin-top: 46px;
            padding-bottom: 23px;
            height: 350px;
        }
    }

    .bottom-box {
        margin-top: 17px;
        padding-bottom: 30px;

        .bottom-wrap {
            background: #FFFFFF;
            border-radius: 8px;
            padding: 33px 30px 0;

            .tips-box {
                margin-top: 24px;
                font-size: 16px;
                color: #666666;
            }
        }

        .list-box {
            padding-top: 42px;
            padding-bottom: 38px;
            background: #ffffff;

            .item-content {

                .item-head {
                    padding: 10px 30px;
                    min-height: 30px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #eeeeee;

                    .head-title {
                        flex: 1;
                        font-size: 16px;
                        color: #333333;

                        .first-title {
                            width: 200px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }

                .no-data {
                    text-align: center;
                    font-size: 16px;
                    color: #333333;
                }
            }

            .item-item {
                display: flex;
                padding: 0 30px 19px;

                &:nth-of-type(2) {
                    padding-top: 19px;
                }

                .item-title {
                    flex: 1;
                    font-size: 14px;
                    color: #666666;
                }
            }
        }
    }

}
